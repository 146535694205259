<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">All Lynks Signups</h2>
      </div>

      <div class="col-md-6">
        <div class="float-right d-flex">
          <button @click="exportData()" class="btn btn-primary btn-site ml-2">
            Export
          </button>
        </div>
      </div>
    </div>
    <div>
      <SectionLoading v-if="loading" text="Signups loading..." />
      <div v-else>
        <div
          v-if="contents && contents.data && contents.data.length == 0"
          class="text-center my-5"
        >
          <p class="text-dark mt-5 pt-5">No Signups yet.</p>
        </div>
        <div v-else>
          <div class="card siteCard">
            <div class="table-responsive py-2">
              <vs-table id="div-with-loading" stripe :data="contents.data">
                <template slot="thead">
                  <vs-th class="text-dark" scope="col">S/N</vs-th>
                  <vs-th class="text-dark" scope="col">Date added</vs-th>
                  <vs-th class="text-dark" scope="col">Artist name</vs-th>
                  <vs-th class="text-dark" scope="col">Release title</vs-th>
                  <vs-th class="text-dark" scope="col">Email</vs-th>
                  <vs-th class="text-dark" scope="col">Action</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(signup, indextr) in data">
                    <td>
                      <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                    </td>
                    <vs-td :data="data[indextr].id">
                      <span class="text-dark">
                        {{
                          moment(new Date(data[indextr].created_at)).fromNow()
                        }}</span
                      >
                    </vs-td>
                    <td>
                      <div>
                        <span
                          class="text-dark mb-0 pl-2 title text-capitalize"
                          >{{ signup.artist_name }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <div>
                        <span
                          class="text-dark mb-0 pl-2 title text-capitalize"
                          >{{ signup.release_name }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <div>
                        <span class="text-dark mb-0 pl-2 title">{{
                          signup.email
                        }}</span>
                      </div>

                      <vs-chip
                        v-if="signup.isUser"
                        class="mt-2"
                        color="success"
                        size="small"
                        >Melior User</vs-chip
                      >
                    </td>

                    <td>
                      <a :href="signup.linkcreated" target="_blank">
                        Visit link
                      </a>
                    </td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>

          <vs-pagination
            v-if="contents"
            class="mt-4"
            :total="Math.ceil(contents.total / table_options.per_page)"
            v-model="table_options.current_page"
          ></vs-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import SectionLoading from "../../../components/SectionLoading";
export default {
  name: "Lynks",
  components: {
    PlusIcon,
    SectionLoading,
  },
  data() {
    return {
      loading: false,
      searchData: "",
      contents: [],
      list: "tile",
      listStyles: [
        { text: "Tile View", value: "tile" },
        { text: "List View", value: "list" },
      ],

      table_options: {
        current_page: 1,
        per_page: 20,
      },
    };
  },
  watch: {
    "table_options.current_page": function () {
      this.getContents(true);
    },
  },
  mounted() {
    this.getBl();
  },
  methods: {
    getBl() {
      this.getContents(false);
    },

    getContents(divLoad) {
      this.loading = true;
      let fetch = {
        path: `/admin/lynks?page=${this.table_options.current_page}&page_size=${this.table_options.per_page}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contents = resp.data.data;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Artisits",
              text: "Unable to get Artists data",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },

    exportData() {
      let data = [];

      for (let i = 0; i < this.contents.data.length; i++) {
        let obj = {
          index: i + 1,
          artist_name: this.contents.data[i].artist_name,
          email: this.contents.data[i].email,
          isUser: this.contents.data[i].isUser ? "Yes" : "No",
          release_name: this.contents.data[i].release_name,
          created_at: this.moment(
            new Date(this.contents.data[i].created_at)
          ).fromNow(),
        };
        data.push(obj);
      }

      const exportToCsv = (filename, rows) => {
        const processRow = (row) => {
          let finalVal = "";
          for (const key in row) {
            let innerValue = row[key] === null ? "" : row[key].toString();
            if (row[key] instanceof Date) {
              innerValue = row[key].toLocaleString();
            }
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            if (finalVal !== "") finalVal += ",";
            finalVal += result;
          }
          return finalVal + "\n";
        };

        let csvFile = "";
        for (const index in rows) {
          csvFile += processRow(rows[index]);
        }

        const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, filename);
        } else {
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      };

      exportToCsv("lynk_data.csv", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-img {
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 100%;
  border-radius: 5px;
}
</style>
